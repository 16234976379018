import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const prospect = `${HOST}prospect`

export default {
  get(context) {
    const params = {
      params: {
        query: context.$store.state.query.query, page: context.$store.state.query.innerpage - 1, user_id: context.employer_id, type_id: context.employer_type_id,
      },
    }
    axios.get(`${prospect}/job/${context.jobId}`, params).then(response => {
      context.setProspects(response.data)
    }, () => {
    })
  },
  addProspect(context1) {
    const context = context1
    context.$vs.loading()
    axios.post(prospect, context.addProspect).then(response => {
      context.$vs.loading.close()
      context.prospectAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotAdded(error.response.data.return_message)
    })
  },
  updateProspect(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.put(`${prospect}/${id}`, context.addprospect).then(response => {
      context.$vs.loading.close()
      context.prospectUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${prospect}/${id}`).then(response => {
      context.$vs.loading.close()
      context.prospectDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotDeleted(error.response.data.return_message)
    })
  },
  getProspectById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${prospect}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setProspect(response.data)
    }, error => {
      context.$vs.loading.close()
      context.prospectNotFound(error.response.data.return_message)
    })
  },

}
