<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <h4 class="card-title mb-0 pt-50">
            Apply for {{ title }}</h4>
        </b-col>
        <b-col
          v-if="types.length > 1"
          cols="4"
        >
          <b-form-group
            label="Provider Type"
            label-cols-md="4"
            class="text-right"
          >
            <v-select
              v-model="employer"
              :options="types"
              :reduce="item => item"
              :clearable="false"
              placeholder="Select"
              @input="onTypeChanged()"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              class="ml-2 w-25"
              :to="{ name: 'candidates-new', params: { job_id: jobId}, query: {name: title}}"
            >
              Apply New
            </b-button>
            <b-button
              variant="outline-primary"
              class="ml-2 w-20"
              :to="{ name: 'jobs'}"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="candidates"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Select -->
        <span v-if="props.column.field === 'cellRendererCheckbox'">
          <b-form-checkbox
            :id="props.row.id"
            v-model="props.row.selected"
            :disabled="!props.row.editable || (selected_prospects.length >= 1 && selected_prospects[0] != props.row.id)"
            @input="prospectSelected(props.row.index -1)"
          />
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              first-number
              last-number
              align="right"
              :per-page="1"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="pageChanged()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <hr>

    <div
      v-if="selected_prospects.length >= 1"
      class="d-flex mt-2"
    >
      <h4 class="mb-0 ml-50">
        Experience
      </h4>
    </div>
    <validation-observer
      v-if="selected_prospects.length >= 1"
      ref="simpleRules"
    >
      <b-form>
        <b-row>
          <b-col
            v-for="option in technologies"
            :key="option.id"
            :value="option.id"
            cols="4"
          >
            <b-form-group
              :label="option.name"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                :name="option.name"
                rules="required"
              >
                <b-form-spinbutton
                  v-model="option.years"
                  :min="0"
                  :max="10"
                  :step="0.5"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="technologies.length == 0"
          class="text-center"
        >
          <p>Experience on technology not available </p>
        </div>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Candidates
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BBadge, BPagination, BFormCheckbox, BForm, BFormGroup, BFormSelect, BButton, BFormSpinbutton,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import store from '@/store/index'
import Constants from '@/js/constant'
import moment from 'moment'
import Candidates from '@/js/candidates'
import Prospect from '@/js/prospects'
import User from '@/js/user'
import LIST from '@/js/lists'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCard,
    BRow,
    BFormCheckbox,
    BCol,
    BBadge,
    BPagination,
    BFormGroup,
    BButton,
    BFormSelect,
    BFormSpinbutton,
    VueGoodTable,

    vSelect,
  },
  data() {
    return {
      required,
      searchQuery: '',
      selected_prospects: [],
      jobId: null,
      title: '',
      types: [],
      employer: null,
      employers: [],
      candidates: [],
      employer_type_id: null,
      employer_id: null,
      technologies: [],
      copytechnologies: [],
      pageLength: 10,
      dir: false,
      count: 1,
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email_addreess',
        },
        {
          label: 'Status',
          field: 'status',
          width: '150px',
        },
        {
          label: 'Select',
          field: 'cellRendererCheckbox',
          width: '150px',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.innerpage
      },
      set(val) {
        this.$store.state.query.innerpage = val
        Prospect.get(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    if (!User.profile().job_exist) {
      if (User.profile().invoice_exist) {
        window.location.hash = '#/invoices'
      } else if (User.profile().timesheet_exist) {
        window.location.hash = '#/timesheets'
      } else {
        // Auth.logout()
      }
      return
    }
    this.jobId = this.$route.params.job_id
    this.title = this.$route.query.name
    this.types = User.profile().job_parent
    if (this.types.length > 0) {
      this.employer_type_id = this.types[0].parent_type_id
      this.employer_id = this.types[0].parent_id
      LIST.getTechnologiesListByJobId(this, this.$route.params.job_id)
      Prospect.get(this)
    }
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          Candidates.addExistingusers(this, this.jobId, this.employer_type_id, this.employer_id, this.selected_prospects, this.technologies, Constants.getEprmMonthDateFromString(moment()))
        }
      })
    },
    setProspects(data) {
      this.candidates = data.prospects
      this.count = data.count
    },
    prospectSelected(position) {
      if (!this.selected_prospects.includes(this.candidates[position].id)) {
        this.selected_prospects.push(this.candidates[position].id)
        this.candidates[position].selected = true
      } else {
        this.selected_prospects.splice(this.selected_prospects.indexOf(this.candidates[position].id), 1)
        this.candidates[position].selected = false
      }
    },
    setTechnologies(technologies) {
      this.technologies = technologies
      this.copytechnologies = technologies
    },
    setTypes(types) {
      this.types = types
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setCandidates(candidatesResponse) {
      this.candidatesResponse = candidatesResponse
    },
    onTypeChanged() {
      this.employer_id = this.employer.parent_id
      Prospect.get(this)
    },
    candidateAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    candidateNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
